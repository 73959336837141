<template>
    <div class="home">
        <el-form class="search_box" ref="form" :model="search" label-width="70px" size='medium' :inline="true">
            <el-form-item label="AI助手名称" label-width="100px">
                <el-input v-model="search.rolename"></el-input>
            </el-form-item>

            <!-- 全部 待发货  待收货  未支付  已完成  已取消 -->
            <el-form-item label-width="0">
                <el-button size='medium' type='primary' @click="serchFn">搜索</el-button>
                <el-button size='medium' type='primary' @click="emptys">重置</el-button>
            </el-form-item>
        </el-form>
        <el-button size='medium' type='primary' @click="AddAss()">创建AI助手</el-button>
        <div class="posr">
            <el-table ref="multipleTableRef" v-loading="loadingShow" :data="tableData" style="width: 100%;margin-top: 20px;"
                :header-cell-style="{ background: '#F7F8FA', color: '#333333' }">
                <el-table-column type="index" label="序号" align="center">
                </el-table-column>
                <el-table-column prop="assname" label="AI助手名称" align="center">
                </el-table-column>
                <el-table-column prop="intro" label="AI简介" align="center">
                </el-table-column>
                <el-table-column prop="headphoto" label="AI头像" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.headphoto" style="width: 50px;height:50px;" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="preposition" label="AI提示词" align="center">
                </el-table-column>
                <el-table-column prop="demo" label="AI示例词" align="center">
                </el-table-column>
                <el-table-column prop="oversize" label="是否展示为方形" align="center">
                    <template slot-scope="scope">
                        {{
                            {
                                0: '否',
                                1: '是'
                            }[scope.row.oversize] || '否'
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="sort" label="排序号" align="center">
                </el-table-column>
                
                <el-table-column prop="createtime" label="新增时间" align="center">
                </el-table-column>

                <el-table-column align="center" fixed="right" width="160px" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="AddAss(scope.row)">修改</el-button>
                        <el-button type="text" @click="FriBanWordDel(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="footer-pagin">
                <el-pagination :current-page.sync="search.page" :page-sizes="pageSizes" :pager-count="5"
                    :page-size="search.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange" />
            </div>
        </div>

        

        <el-dialog :title="id ? '修改' : '新增'" :visible.sync="examineDialog" @close="closeDialog" :close-on-click-modal="false" width="600px">
            <div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="AI名称" prop="assname">
                        <el-input v-model.trim="ruleForm.assname"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="AI简介" prop="intro">
                        <el-input v-model="ruleForm.intro" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="AI提示词" prop="preposition">
                        <el-input v-model="ruleForm.preposition" type="textarea" rows="6"></el-input>
                    </el-form-item>
                    <el-form-item label="AI头像" prop="fileList">
                        <element-upload
                            ref="upload"
                            :action="baseUrl"
                            list-type="picture-card"
                            accept=".png,.jpg"
                            :headers="token"
                            :data="dataObj"
                            :file-list="fileList"
                            :limit="1"
                            :before-upload="beforeUpload"
                            :on-error="uploadError"
                            :on-success="uploadSuccess"
                            :on-remove="handleRemove">
                            <i class="el-icon-plus"></i>
                        </element-upload>
                    </el-form-item>
                    <el-form-item label="背景图" prop="fileListBg">
                        <element-upload
                            ref="uploadBg"
                            :action="baseUrl"
                            list-type="picture-card"
                            accept=".png,.jpg"
                            :headers="token"
                            :data="dataObj"
                            :file-list="fileListBg"
                            :limit="1"
                            :before-upload="beforeUpload"
                            :on-error="uploadError"
                            :on-success="uploadSuccessBg"
                            :on-remove="handleRemoveBg">
                            <i class="el-icon-plus"></i>
                        </element-upload>
                    </el-form-item>
                    <el-form-item label="AI示例词" prop="demo">
                        <el-input v-model="ruleForm.demo" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="字数限制" prop="wordLength">
                        <el-input-number v-model="ruleForm.wordLength" :min="0" :controls='false'></el-input-number>
                    </el-form-item>
                    <el-form-item label="是否展示方形" prop="overSize">
                        <el-radio-group v-model="ruleForm.overSize">
                            <el-radio :label="0">否</el-radio>
                            <el-radio :label="1">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="排序" prop="sort">
                        <el-input-number v-model="ruleForm.sort" :min="0" :controls='false'></el-input-number>
                    </el-form-item>
                    <el-form-item label="条件名称">
                        <div v-for="(item,index) in ruleForm.conditions" :key="index">
                            <el-form-item label-width="80px" :label="'条件'+(index+1)" :prop="'conditions.' + index + '.condition'" :rules="rules.condition" class="conditions-box">
                                <i class="el-icon-delete" @click="ruleForm.conditions.splice(index,1)"></i>
                                <el-input v-model.trim="item.condition"></el-input>
                            </el-form-item>
                            <div style="padding-left: 60px">
                                <el-form-item label-width="80px" label="使用位置" :prop="'conditions.' + index + '.location'" :rules="rules.location" class="conditions-box">
                                    <el-select v-model="item.location" placeholder="请选择使用位置" size="large">
                                        <el-option :value="0" label="前置条件"/>
                                        <el-option :value="1" label="后置条件"/>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label-width="80px" label="使用控件" :prop="'conditions.' + index + '.control'" :rules="rules.control" class="conditions-box">
                                    <el-select v-model="item.control" placeholder="请选择使用控件" size="large" @change="controlChange(index)">
                                        <el-option :value="0" label="单文本"/>
                                        <el-option :value="1" label="下拉"/>
                                        <el-option :value="2" label="平铺选择"/>
                                        <el-option :value="3" label="富文本"/>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div v-for="(items,ids) in item.optionsArr" :key="ids" style="padding-left: 60px">
                                <el-form-item label-width="80px" :label="'条件值'+(ids+1)" :prop="'conditions.' + index + '.optionsArr.' + ids + '.value'" :rules="rules.value"  class="conditions-box">
                                    <i class="el-icon-delete" @click="ruleForm.conditions[index].optionsArr.splice(ids,1)" v-if="item.optionsArr.length > 1"></i>
                                    <el-input v-model.trim="items.value" maxlength="20"></el-input>
                                </el-form-item>
                            </div>
                            <el-button type="text" v-if="item.control == 1 || item.control == 2" @click="ruleForm.conditions[index].optionsArr.push({value:''})" style="margin-left: 60px">添加条件值</el-button>
                        </div>
                        <el-button type="text" v-if="ruleForm.conditions.length<5" @click="ruleForm.conditions.push({condition: '',control: 0,optionsArr: [{value:''}]})">添加条件</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="examineDialog = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>
  
<script>
import ElementUpload from '@/components/ElementUpload/index.vue'
import { GetAdminAssPage, AddAss, UpdateAss, DelAss } from '@/service/aiAssistant/aiAssistant.js'

// @ is an alias to /src
import {
    MessageBox, Message
} from 'element-ui'
import { base } from '@/service/api.js'
export default {
    name: 'Home',
    components: {
        ElementUpload
    },
    data() {
        var fileListArr = (rule, value, callback) => {
            if (this.fileList.length === 0) {
                return callback(new Error("请上传图片"));
            } else {
                return callback();
            }
        };
        var fileListBgArr = (rule, value, callback) => {
            if (this.fileListBg.length === 0) {
                return callback(new Error("请上传图片"));
            } else {
                return callback();
            }
        };
        
        return {
            loadingShow: false,
            roleTypeVisible: false,
            ruleForm: {
                conditions: [{condition: '',control: 0,location:0,optionsArr: [{value:''}]}]
            },
            rules: {
                assname: [
                    { required: true, message: '请输入AI名称', trigger: 'blur' },
                ],
                intro: [
                    { required: true, message: '请输入AI简介', trigger: 'blur' },
                ],
                preposition: [
                    { required: true, message: '请输入AI提示词', trigger: 'blur' },
                ],
                demo: [
                    { required: true, message: '请输入AI示例词', trigger: 'blur' },
                ],
                wordLength: [
                    { required: true, message: '请输入字数限制', trigger: 'blur' },
                ],
                fileList: [
                    { validator: fileListArr, trigger: 'blur' }
				],
                fileListBg: [
                    { validator: fileListBgArr, trigger: 'blur' }
				],
                overSize: [
                    { required: true, message: '是否展示方形', trigger: 'change' },
                ],
                sort: [
                    { required: true, message: '请输入排序', trigger: 'blur' },
                ],
                condition: [
                    { required: true, message: '请输入条件', trigger: 'blur' },
                ],
                value: [
                    { required: true, message: '请输入条件值', trigger: 'blur' },
                ],
                control: [
                    { required: true, message: '请选择使用控件', trigger: 'change' },
                ],
                location: [
                    { required: true, message: '请选择使用位置', trigger: 'change' },
                ],
            },
            id: '',
            tableData: [],
            search: {
                page: 1,
                pageSize: 10,
            },
            pageSizes: [10, 20, 50, 100],
            total: 0,
            examineDialog: false,
            fileList: [],
            fileListBg: [],
            baseUrl: base + '/File/UploadPhoto',
            token: {
                Authorization: 'bearer ' + localStorage.getItem('accesstoken'),
                'X-Authorization': 'bearer ' + localStorage.getItem('refreshtoken')
            },
            dataObj: {
				tag: 'product'
			},
        }
    },
    created() {
        this.gettableList();
    },
    methods: {
        AddAss(rowData) {
            this.id = rowData ? rowData.id : ''
            if (rowData) {
                let row = JSON.parse(JSON.stringify(rowData))
                this.$set(this.ruleForm, 'id', row.id)
                this.$set(this, 'fileList', [{url: row.headphoto}])
                this.$set(this, 'fileListBg', [{url: row.cover}])
                
                this.$set(this.ruleForm, 'intro', row.intro)
                this.$set(this.ruleForm, 'preposition', row.preposition)
                this.$set(this.ruleForm, 'demo', row.demo)
                this.$set(this.ruleForm, 'assname', row.assname)
                this.$set(this.ruleForm, 'overSize', row.oversize)
                this.$set(this.ruleForm, 'sort', row.sort)
                this.$set(this.ruleForm, 'wordLength', row.wordlength)
                row.conditions = row.conditions || []
                // let conditions = []
                row.conditions.map(item=>{
                    let optionsArr = []
                    item.options.map(items=>{
                        optionsArr.push({value: items})
                    })
                    item.optionsArr = optionsArr
                })
                this.$set(this.ruleForm, 'conditions', row.conditions)
            }
            
            this.examineDialog = true
        },
        handleRemove(file, fileList) {
            console.log('移除', file, fileList)
            console.log(this.$refs.upload.clearFiles())
            this.fileList = fileList
        },
        uploadSuccess(file) {
			console.log('成功',file)
			if(file.statuscode===200){
				this.fileList.push({
					url: file.data.url,
				})
			}
        },
        handleRemoveBg(file, fileList) {
            console.log('移除', file, fileList)
            console.log(this.$refs.uploadBg.clearFiles())
            this.fileListBg = fileList
        },
        uploadSuccessBg(file) {
			console.log('成功',file)
			if(file.statuscode===200){
				this.fileListBg.push({
					url: file.data.url,
				})
			}
        },
        beforeUpload(file) {
        // console.log(file)
        if (file.size > 1 * 1024 * 1024) {
                        Message.error(`请上传小于1M图片`)
            return false;
        }
        return true;
        },
        uploadError(err){
            console.log('失败1',err.response)	
            console.log('失败2',err.data)
            console.log('失败3',err.headers)
            console.log('失败4',err.headers['access-token'])
        },
        beforeAvatarUpload(file) {
            console.log(file)
        },
        preview() {
            console.log('图片预览')
        },
        // 上传失败
        errorUpload() {
            Message.error('上传失败')
        },
        controlChange(index){
            // if(this.ruleForm.conditions[index].control == 0 || this.ruleForm.conditions[index].control == 3){
                this.ruleForm.conditions[index].optionsArr =  [{value:''}]
            // }
        },
        closeDialog() {
            this.ruleForm = {conditions: [{condition: '',control: 0,location: 0,optionsArr: [{value:''}]}]}
            this.fileList = []
            this.fileListBg = []
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) return;
                this.loadingShow = true
                let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
                ruleForm.headPhoto = this.fileList[0].url
                ruleForm.cover = this.fileListBg[0].url
                console.log(ruleForm.conditions)
                ruleForm.conditions.map(item=>{
                    console.log(item)
                    let options = []
                    item.optionsArr.map(items=>{
                        options.push(items.value)
                    })
                    item.options = options
                })
                if (this.id) {
                    UpdateAss({ ...ruleForm }).then(res => {
                        this.loadingShow = false;
                        console.log(res)
                        this.examineDialog = false
                        Message.success(`修改成功`)
                        this.gettableList()
                    }).catch(err => {
                        console.log(err)
                        this.loadingShow = false;
                    })
                } else {
                    AddAss({ ...ruleForm }).then(res => {
                        this.loadingShow = false;
                        console.log(res)
                        this.examineDialog = false
                        Message.success(`新增成功`)
                        this.gettableList()
                    }).catch(err => {
                        console.log(err)
                        this.loadingShow = false;
                    })
                }

            });
        },
        serchFn() {
            this.gettableList();
        },
        emptys() {
            for (const key in this.search) {
                this.search[key] = undefined
            }
            this.search.page = 1
            this.search.pageSize = 10
            this.serchFn()
        },
        gettableList() {
            this.loadingShow = true
            const query = {
                ...this.search
            }

            GetAdminAssPage(query).then(res => {
                this.loadingShow = false;
                console.log(res)
                this.tableData = res.data.list
                this.total = res.data.count
            }).catch(err => {
                console.log(err)
                this.loadingShow = false;
            })
        },
        // 每页的条数
        handleSizeChange(val) {
            this.search.pageSize = val;
            this.gettableList();
        },
        // 分页
        handleCurrentChange(val) {
            this.search.page = val;
            this.gettableList();
        },
        FriBanWordDel(row) {
            MessageBox.confirm('请确认是否删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loadingShow = true
                DelAss({
                    id: row.id,
                }).then(res => {
                    console.log(res)
                    this.loadingShow = false
                    Message.success(`删除成功`)
                    this.gettableList();
                }).catch(err => {
                    this.loadingShow = false
                    console.log(err)
                })
            }).catch(() => {
                Message.info('取消删除!')
            })
        }
    }
}
</script>
<style lang="less" scoped>
.posr {
    position: relative;
}

.footer-pagin {
    margin: 20px;
    text-align: right;
}

.t-c {
    text-align: center;
}
.conditions-box{
    margin-bottom: 15px;
    padding-right: 30px;
    position: relative;
    .el-icon-delete{
        position: absolute;
        right: -30px;
        font-size: 16px;
        top: 0;
        color: red;
        cursor: pointer;
    }
}
</style>
  
  